@font-face {
  font-family: 'icomoon';
  src:  url('../../../fonts/icomoon/icomoon.eot?ihhhxz');
  src:  url('../../../fonts/icomoon/icomoon.eot?ihhhxz#iefix') format('embedded-opentype'),
    url('../../../fonts/icomoon/icomoon.ttf?ihhhxz') format('truetype'),
    url('../../../fonts/icomoon/icomoon.woff?ihhhxz') format('woff'),
    url('../../../fonts/icomoon/icomoon.svg?ihhhxz#icomoon') format('svg');
  font-weight: normal;
  font-style: normal;
  font-display: block;
}

// i {
//   /* use !important to prevent issues with browser extensions that change fonts */
//   font-family: 'icomoon' !important;
//   speak: never;
//   font-style: normal;
//   font-weight: normal;
//   font-variant: normal;
//   text-transform: none;
//   line-height: 1;

//   /* Better Font Rendering =========== */
//   -webkit-font-smoothing: antialiased;
//   -moz-osx-font-smoothing: grayscale;
// }

// .goodpoker-bankroll-menu-icon:before {
//   content: "\e900";
//   color: #56915d;
// }
// .goodpoker-community-menu-icon:before {
//   content: "\e901";
//   color: #56915d;
// }
// .goodpoker-import-menu-icon:before {
//   content: "\e902";
//   color: #56915d;
// }
// .goodpoker-my-group-menu-icon:before {
//   content: "\e903";
//   color: #56915d;
// }
// .goodpoker-study-room-menu-icon:before {
//   content: "\e904";
//   color: #56915d;
// }

[class^="goodpoker-"], [class*=" goodpoker-"] {
  /* use !important to prevent issues with browser extensions that change fonts */
  font-family: 'icomoon' !important;
  speak: never;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;

  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.goodpoker-bankroll-menu-icon:before {
  content: "\e900";
  color: #56915d;
}
.goodpoker-community-menu-icon:before {
  content: "\e901";
  color: #56915d;
}
.goodpoker-import-menu-icon:before {
  content: "\e902";
  color: #56915d;
}
.goodpoker-my-groups-menu-icon:before {
  content: "\e903";
  color: #56915d;
}
.goodpoker-study-room-menu-icon:before {
  content: "\e904";
  color: #56915d;
}
