@import 'bootstrap';
@import 'bootstrap-extended';
@import 'colors';
@import 'components';
@import "themes/layout-dark";
@import "core/menu/horizontal-menu";
@import 'overrides';
@import '../sass/core/mixins/_icons-custom.scss';
@import '../css/rrweb';

@import '~@ctrl/ngx-emoji-mart/picker';

h1,h2,h3,h4,h5,h6,
.h1,.h2,.h3,.h4,.h5,.h6{
  font-family: $font-family-sans-serif-title;
}

.custom-z-index-modal,
.custom-z-index-modal-watch-video{

  overflow: hidden!important;
  height: auto!important;
  -webkit-tap-highlight-color: rgba(255, 255, 255, 0);
  -webkit-tap-highlight-color: transparent;

  .app-sidebar{
    z-index:initial!important;
  }
  .navbar{
    z-index: initial!important;
  }
}
.app-sidebarW60 {
  width: 60px !important;
}

.color-green {
  color: #4B7C50!important;
}
